import log from 'core/log';
import { store } from 'store';

const showAttentiveSignUp = () => {
    if (window.__attentive) {
        if (window.innerWidth >= 760) {
            //call the desktop creative
            window.__attentive.trigger(null, null, null, [55022]);
        } else {
            //call the mobile creative
            window.__attentive.trigger(null, null, null, [55015]);
        }
    }
};

const productView = product => {
    try {
        if (window.attentive && window.attentive.analytics) {
            const {
                user: {
                    auth: { authenticated, profile }
                }
            } = store.getState();

            let user = undefined;
            if (authenticated) {
                user = {
                    phone: profile.phone,
                    email: profile.email
                };
            }

            let name = product.ecommerce.name;

            if (product.shadeName) {
                name = name + ' - ' + product.shadeName;
            }

            if (product.size) {
                name = name + ' - ' + product.size;
            }

            window.attentive.analytics.productView({
                items: [
                    {
                        productId: product.productCode,
                        productVariantId: product.sku,
                        productImage: product.packshot.medium(),
                        name,
                        price: {
                            value: Number(product.price),
                            currency: 'USD'
                        },
                        category: product.zone
                    }
                ],
                user
            });
        }
    } catch (err) {
        log.info('Error sending attv product view event', err);
    }
};

const addToCart = (product, quantity) => {
    try {
        if (window.attentive && window.attentive.analytics) {
            const {
                user: {
                    auth: { authenticated, profile }
                }
            } = store.getState();

            let user = undefined;
            if (authenticated) {
                user = {
                    phone: profile.phone,
                    email: profile.email
                };
            }

            let name = product.ecommerce.name;

            if (product.shadeName) {
                name = name + ' - ' + product.shadeName;
            }

            if (product.size) {
                name = name + ' - ' + product.size;
            }

            window.attentive.analytics.addToCart({
                items: [
                    {
                        productId: product.productCode,
                        productVariantId: product.sku,
                        productImage: product.packshot.medium(),
                        name,
                        price: {
                            value: Number(product.price),
                            currency: 'USD'
                        },
                        quantity,
                        category: product.zone
                    }
                ],
                cart: {
                    cartId: profile.cartId
                },
                user
            });
        }
    } catch (err) {
        log.info('Error sending attv add to cart event', err);
    }
};

const purchase = (products, email, phone) => {
    try {
        if (window.attentive && window.attentive.analytics) {
            const timestamp = Date.now();

            const {
                user: {
                    auth: { authenticated, profile }
                }
            } = store.getState();

            const user = {
                phone: authenticated ? profile.phone : phone,
                email: authenticated ? profile.email : email
            };

            const items = products.map(p => {
                let name = p.ecommerce.name;

                if (p.shadeName) {
                    name = name + ' - ' + p.shadeName;
                }

                if (p.size) {
                    name = name + ' - ' + p.size;
                }

                return {
                    productId: p.productCode,
                    productVariantId: p.sku,
                    productImage: p.packshot.medium(),
                    name,
                    price: {
                        value: Number(p.price),
                        currency: 'USD'
                    },
                    quantity: p.quantity,
                    category: p.zone
                };
            });

            window.attentive.analytics.purchase({
                items,
                order: {
                    orderId: profile.cartId + '-' + timestamp
                },
                cart: {
                    cartId: profile.cartId
                },
                user
            });
        }
    } catch (err) {
        log.info('Error sending attv purchase event', err);
    }
};

const attentiveHelper = {
    showAttentiveSignUp,
    productView,
    addToCart,
    purchase
};

export default attentiveHelper;
