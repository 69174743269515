import { combineActions, createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';
import { APPOINTMENT } from 'constants/gifts';

const SET_DISPLAY_GIFTS_PAGE = 'SET_DISPLAY_GIFTS_PAGE';
const GIFT_SET_SERVICE = 'GIFT_SET_SERVICE';
const GIFT_SET_DATA = 'GIFT_SET_DATA';
const GIFT_RESET = 'GIFT_RESET';
const GIFT_PURCHASE = 'GIFT_PURCHASE';
const GIFT_VALIDATE = 'GIFT_VALIDATE';
const GIFT_SET_VALID_CODE = 'GIFT_SET_VALID_CODE';
const KIT_GIFT_PURCHASE = 'KIT_GIFT_PURCHASE';

const initialState = {
    service: null,
    giftData: null,
    error: null,
    isFetching: false,
    validCode: null
};

const giftsPageInitialState = {
    displayGiftsPage: false
};

export const setService = createAction(GIFT_SET_SERVICE);
export const resetGifts = createAction(GIFT_RESET);
export const setDisplayGiftsPage = createAction(SET_DISPLAY_GIFTS_PAGE);
export const setValidCode = createAction(GIFT_SET_VALID_CODE);
const setData = createAction(GIFT_SET_DATA);

// /gift/submit
const submitGiftPurchase = createAction(FETCH, giftInfo => ({
    prefix: GIFT_PURCHASE,
    endpoint: `${CONFIG.API_URL}/gifts/submit`,
    options: {
        method: 'POST',
        body: JSON.stringify(giftInfo)
    }
}));

export const purchaseKitGift = createAction(
    FETCH,
    (creditCardData, shippingAddress, giftInfo, email) => ({
        prefix: KIT_GIFT_PURCHASE,
        endpoint: `${CONFIG.API_URL}/gifts/kit/submit`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                creditCardData,
                shippingAddress,
                giftInfo,
                email
            })
        }
    })
);

export const purchaseGift = creditCardData => {
    return (dispatch, getState) => {
        const { gifts: { service, giftData }, user: { auth: { profile: { externalId } } } } = getState();

        let bookableInfo;

        if (service.dropdownType === APPOINTMENT) {
            bookableInfo = {
                startTime: service.bookableInfo.startTime,
                endTime: service.bookableInfo.endTime,
                staffId: service.bookableInfo.staffId,
                staffName: service.bookableInfo.staffName,
                sessionTypeId: service.bookableInfo.sessionType,
                sessionLength: service.bookableInfo.sessionLength,
                sessionName: service.bookableInfo.sessionName,
                locationId: service.bookableInfo.locationId
            };
        } else {
            bookableInfo = service.bookableInfo;
        }

        // Capitalize first letter in name
        const splittedName = giftData.recipientName.trim().split(' ');
        const recipientName = splittedName.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(' ');

        const request = {
            externalId,
            service: {
                name: service.name,
                price: service.price,
                key: service.key,
                type: service.dropdownType.toLowerCase(),
                bookableInfo
            },
            giftData: {
                recipientName: recipientName,
                recipientEmail: giftData.recipientEmail.toLowerCase().trim(),
                personalNote: giftData.personalNote ?
                    giftData.personalNote.replace(/<div>|<br>|<br\/>|&nbsp;/g, ' ')
                        .replace(/<\/div>/g, '')
                        .replace(/&amp;/g, '&')
                        .replace(/\s+/g, ' ')
                        .trim() :
                    undefined
            },
            creditCardData
        };

        return dispatch(submitGiftPurchase(request));
    };
};

const getGiftCodeValidity = createAction(FETCH, giftCode => ({
    prefix: GIFT_VALIDATE,
    endpoint: `${CONFIG.API_URL}/gifts/validate`,
    options: {
        method: 'POST',
        body: JSON.stringify(giftCode)
    }
}));

export const validateGiftCode = (giftCode, serviceKey, eventId) => {
    return (dispatch) => {
        dispatch(setValidCode(null));

        const service = serviceKey === 'master_class_box' ? 'master_class' : serviceKey;
        const request = {
            code: giftCode.toLowerCase(),
            service,
            eventId
        };
        return dispatch(getGiftCodeValidity(request));
    };
};

export const setGiftData = giftData => {
    return (dispatch) => {
        return Promise.resolve(dispatch(setData(giftData)));
    };
};

export default handleActions({
    [GIFT_SET_SERVICE]: (state, { payload }) => ({
        ...state,
        service: {
            name: payload.name,
            price: payload.price,
            key: payload.key,
            id: payload.id,
            dropdownType: payload.dropdownType,
            dropdownServiceType: payload.dropdownServiceType,
            bookableInfo: payload.bookableInfo
        }
    }),
    [GIFT_SET_DATA]: (state, { payload }) => ({
        ...state,
        giftData: {
            senderName: payload.senderName,
            recipientName: payload.recipientName,
            recipientEmail: payload.recipientEmail,
            personalNote: payload.personalNote
        }
    }),
    [SET_DISPLAY_GIFTS_PAGE]: (state, { payload }) => ({
        ...state,
        displayGiftsPage: payload
    }),
    [combineActions(
        `${GIFT_PURCHASE}/${FETCH_PENDING}`,
        `${KIT_GIFT_PURCHASE}/${FETCH_PENDING}`
    )]: state => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [combineActions(
        `${GIFT_PURCHASE}/${FETCH_SUCCESS}`,
        `${KIT_GIFT_PURCHASE}/${FETCH_SUCCESS}`
    )]: state => ({
        ...state,
        isFetching: false,
    }),
    [combineActions(
        `${GIFT_PURCHASE}/${FETCH_ERROR}`,
        `${KIT_GIFT_PURCHASE}/${FETCH_ERROR}`
    )]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [GIFT_SET_VALID_CODE]: (state, { payload }) => ({
        ...state,
        validCode: payload
    }),
    [GIFT_RESET]: state => ({
        ...state,
        initialState
    })
}, { ...initialState, ...giftsPageInitialState });
