import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import { styledProps, rem } from 'core/styled';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import PropTypes from 'prop-types';
import whiteLogo from 'assets/logo-alternative-inverted.svg';
import { ROUTES } from 'constants/routes';
import { EVENTS } from 'constants/analytics';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import attentiveHelper from 'core/utils/attentiveHelper';

const FooterFlexContainer = styled(Flex)`
    background: ${styledProps('color', 'black')};
    color: ${styledProps('color', 'white')};
    width: 100%;
    padding-top: ${rem(40)};
    margin-top: ${rem(47)};

    ${greaterThan(breakpoints.large)(css`
        padding-top: ${rem(80)};
    `)}
`;

const FooterList = styled.ul`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
`;

const LinksWrapper = styled(Flex)`
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;

    ${greaterThan(breakpoints.large)(css`
        width: 100%;
        max-width: ${rem(1100)};
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    `)}
`;

const MiniLinksWrapper = styled(Flex)`
    ${greaterThan(breakpoints.large)(css`
        ${({ widthDesktop }) => widthDesktop ? `width: ${rem(widthDesktop)};` : ''}
    `)}
`;

const LiLink = styled.li`
    ${greaterThan(breakpoints.large)(css`
        ${({ widthDesktop }) => widthDesktop ? `width: ${rem(widthDesktop)};` : ''}
    `)}
`;

const Link = styled.a`
    display: block;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${({ fontSize }) => rem(fontSize)};
    padding-bottom: ${({ cssPaddingBottom }) => rem(cssPaddingBottom ? cssPaddingBottom : 20)};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    .shocking-pink {
        color: ${styledProps('color', 'shockingPink')};
    }
    
    &:link {
        color: ${(props) => styledProps('color', props.miniLink ? 'adaGrey' : 'white')(props)};
    }

    &:visited {
        color: ${(props) => styledProps('color', props.miniLink ? 'adaGrey' : 'white')(props)};
    }

    ${greaterThan(breakpoints.large)(css`
        ${({ fontSizeDesktop }) => fontSizeDesktop ? `font-size: ${rem(fontSizeDesktop)};` : ''}
    `)}
`;

const PseudoLink = styled(NoStyleButton)`
    display: block;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${({ fontSize }) => rem(fontSize)};
    padding-bottom: ${({ cssPaddingBottom }) => rem(cssPaddingBottom ? cssPaddingBottom : 20)};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    width: 100%;
    color: ${styledProps('color', 'white')};

    .shocking-pink {
        color: ${styledProps('color', 'shockingPink')};
    }

    ${greaterThan(breakpoints.large)(css`
        ${({ fontSizeDesktop }) => fontSizeDesktop ? `font-size: ${rem(fontSizeDesktop)};` : ''}
    `)}
`;

const Address = styled.div`
    padding-bottom: ${rem(15)};

    ${greaterThan(breakpoints.large)(css`
        padding-bottom: ${rem(55)};
    `)}
`;

const HomeLink = styled.a`
    text-transform: uppercase;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(18)};
    padding-top: ${rem(10)};
    padding-bottom: ${rem(80)};
    font-weight: bold;
    display: block;
    color: ${styledProps('color', 'white')};

    ${greaterThan(breakpoints.small)(css`
        padding-top: ${rem(20)};
        font-size: ${rem(22)};
    `)}
`;

const StyledLogo = styled.img`
    padding-bottom: ${rem(20)};
    width: ${rem(280)};

    ${greaterThan(breakpoints.large)(css`
        width: ${rem(350)};
        padding-bottom: ${rem(40)};
    `)}
`;


const footerAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'footer'
};

// TODO: Connect footer data to Prismic CMS

export default class Footer extends Component {
    static propTypes = {
        address: PropTypes.string.isRequired,
        addressText: PropTypes.string.isRequired,
        chanelHomeUrl: PropTypes.string.isRequired,
        logoUrl: PropTypes.string.isRequired,
        links: PropTypes.array.isRequired,
        miniLinks: PropTypes.array,
        analyticsTag: PropTypes.func.isRequired
    };

    analytics = (properties) => {
        this.props.analyticsTag({
            ...footerAnalytics,
            ...properties
        }, { userInfo: true });
    };

    openAttentivePopup = () => {
        attentiveHelper.showAttentiveSignUp();

        this.analytics({ eventAction: 'Newsletter' });
    };

    /**
     * Render links in footer
     * @returns { component } links
     */
    renderLinks = () => {
        const { links } = this.props;

        return links.map((link, key) => {
            const rel = {};
            if (link.newWindow) {
                rel.rel = 'noopener noreferrer';
            }

            if (key + 1 === links.length) {
                return (
                    <li key={key}>
                        <MiniLinksWrapper
                            as={'ul'}
                            flexDirection="column"
                            justifyContent="center"
                            widthDesktop={175}
                        >
                            <LiLink widthDesktop={175}>
                                <Link href={ link.url || '#' } target={link.newWindow ? '_blank' : '_self' } fontSize={12} fontSizeDesktop={15} widthDesktop={175} onClick={() => this.analytics({ eventAction: link.text })} {...rel}>{ link.text }</Link>
                            </LiLink>
                            {this.renderMiniLinks()}
                        </MiniLinksWrapper>
                    </li>
                );
            }

            if (!!window.__attentive && link.text.toLowerCase().includes('newsletter')) {
                return (
                    <LiLink widthDesktop={175} key={ key } >
                        <PseudoLink fontSize={12} fontSizeDesktop={15} widthDesktop={175} onClick={this.openAttentivePopup}>{ link.text }</PseudoLink>
                    </LiLink>
                );
            }

            return (
                <LiLink widthDesktop={175} key={ key } >
                    <Link href={ link.url || '#' } target={link.newWindow ? '_blank' : '_self' } fontSize={12} fontSizeDesktop={15} widthDesktop={175} onClick={() => this.analytics({ eventAction: link.text })} {...rel}>{ link.text }</Link>
                </LiLink>
            );
        });
    };

    /**
     * Render minilinks in footer
     * @returns { component } links
     */
    renderMiniLinks = () => {
        const { miniLinks } = this.props;

        if (!miniLinks) {
            return null;
        }

        return miniLinks.map((link, key) => {
            const rel = {};
            if (link.newWindow) {
                rel.rel = 'noopener noreferrer';
            }

            return (
                <LiLink widthDesktop={175} key={ key }>
                    <Link
                        miniLink
                        href={ link.url || '#' }
                        target={link.newWindow ? '_blank' : '_self' }
                        fontSize={10}
                        fontSizeDesktop={12}
                        widthDesktop={175}
                        cssPaddingBottom={15}
                        onClick={() => this.analytics({ eventAction: link.text })}
                        {...rel}
                    >
                        { link.text }
                    </Link>
                </LiLink>
            );
        });
    };

    render() {
        const { address, addressText, chanelHomeUrl } = this.props;

        return (
            <FooterFlexContainer
                justifyContent="flex-end"
                alignItems="center"
                fullHeight={false}
                flexDirection="column"
                id={'atelier--footer'}
                as={'footer'}
                role={'contentinfo'}
            >
                <FooterList>
                    <Box as={'li'}>
                        <a href={ROUTES.HOME} onClick={() => this.analytics({ eventAction: 'home' })}>
                            <StyledLogo src={whiteLogo} alt={'Atelier Beauté Chanel, go to home page'} role={'img'} />
                        </a>
                    </Box>

                    <Box as={'li'}>
                        <Address>
                            <Link target='_blank'
                                href={`https://maps.google.com/?q=${address}`}
                                rel={'noopener noreferrer'}
                                fontSize={16}
                                fontSizeDesktop={20}
                                onClick={() => this.analytics({ eventAction: 'map' })}
                                dangerouslySetInnerHTML={{ __html: addressText }}/>
                        </Address>
                    </Box>

                    <Box as={'li'} width={.95}>
                        <LinksWrapper
                            as={'ul'}
                            flexDirection="column"
                            justifyContent="center"
                        >
                            { this.renderLinks() }

                        </LinksWrapper>
                    </Box>

                    <Box as={'li'}>
                        <HomeLink href={ chanelHomeUrl } target='_blank' rel='noopener noreferrer' onClick={() => this.analytics({ eventAction: 'chanel.com' })}>CHANEL.COM</HomeLink>
                    </Box>
                </FooterList>

            </FooterFlexContainer>
        );
    }
}
