import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { resolveKey, noop, resolveBooleanKey } from 'core/utils';
import { finalDotRegex } from 'constants/regex';
import PasswordInput from 'components/GlobalComponents/FormInput/PasswordInput';
import PhoneInput from 'components/GlobalComponents/FormInput/PhoneInput';
import DateInput from 'components/GlobalComponents/FormInput/DateInput';
import SelectInput from 'components/GlobalComponents/FormInput/SelectInput';
import ThreeFieldsDate from 'components/GlobalComponents/FormInput/ThreeFieldsDate';
import {
    TextInput,
    InputContainer,
    InputCaption,
    InputError,
    InputLabel,
    InputUnderline
} from 'components/GlobalComponents/FormInput/FormInput.styled';
import styled, { css } from 'styled-components';
import { styledProps, rem, internetExplorerOnlyStyles } from 'core/styled';
import { isMobile } from 'core/utils/browserDetect';
import withMobileKeyboard from 'core/utils/withMobileKeyboard';

// for maxlength, ignore backspace, the arrowkeys and delete key
const ignoredKeyCodes = [8, 37, 38, 39, 40, 46];

// conditional props overrides of default styles
const StyledPhoneInput = styled(PhoneInput)`
    font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
    font-size: ${({ inputfontsize }) => inputfontsize ? rem(inputfontsize) : '' };
    text-align: ${({ inputtextalign }) => inputtextalign ? inputtextalign : '' };
    &:disabled {
        color: ${styledProps('color', 'silverChalice')};
        -webkit-text-fill-color: ${styledProps('color', 'silverChalice')};
        opacity: 1;
    }
    &::-ms-clear {
        width : 0;
        height: 0;
    }
`;


const StyledInputLabel = styled(InputLabel)`
    font-family: ${({ labelFont }) => labelFont ? styledProps('font', labelFont) : ''};
    font-size: ${({ labelFontSizeNonEmpty }) => labelFontSizeNonEmpty ? rem(labelFontSizeNonEmpty) : '' };
    color: ${({ labelColor }) => labelColor ? styledProps('color', labelColor) : '' };
    opacity: ${({ hideOnFocus }) => hideOnFocus ? '0' : '0.9'};
    transition: all 0.25s ease;
    ${({ labelWeight }) => labelWeight ? `font-weight: ${labelWeight};` : ''} 
`;

const StyledInputUnderline = styled(InputUnderline)`
    border-color: ${({ borderColor }) => borderColor ? styledProps('color', borderColor) : ''};
    ${({ underlineWidth }) => underlineWidth ? `border-width: ${underlineWidth}` : null };
`;

const PseudoTextArea = styled.div`
        font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
        font-size: ${({ inputfontsize }) => inputfontsize ? rem(inputfontsize) : '' };
        text-align: ${({ inputtextalign }) => inputtextalign ? inputtextalign : '' };
        color: ${({ inputfontcolor }) => inputfontcolor ? styledProps('color', inputfontcolor) : ''};
        resize: none;
        width: 100%;
        border: none;
        outline: none;
        max-width: ${({ fullWidth }) => fullWidth ? 100 : 90}%;
        padding-right: ${rem(20)};
        // This allows passing minHeight to control the height of the TextArea when it is not editable
        height: ${({ contentEditable, minHeight }) => contentEditable ? 'auto' : minHeight ? rem(minHeight) : 'auto'};
        // this allows to control initial block height when content is editable
        min-height: ${({ multiLineInitialBlockHeight }) => multiLineInitialBlockHeight ? multiLineInitialBlockHeight : null }; 
        ${({ padding }) => padding && padding.length ? `padding: ${rem(...padding)};` : null}
        
        ${({ displayFlexMultiline }) => displayFlexMultiline ? `
            display: flex;
            flex-direction: column;
            justify-content: center;
        ` : ''}
        
        &:disabled {
            color: ${styledProps('color', 'silverChalice')};
            -webkit-text-fill-color: ${styledProps('color', 'silverChalice')};
            opacity: 1;
        }

  ${internetExplorerOnlyStyles(css`
     word-wrap: break-word;
  `)}
`;

const StyledTextInput = styled(TextInput)`
    font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
    font-size: ${({ inputfontsize }) => inputfontsize ? rem(inputfontsize) : '' };
    color: ${({ inputfontcolor }) => inputfontcolor ? styledProps('color', inputfontcolor) : ''};
    ${({ inputtextalign }) => inputtextalign ? `text-align: ${inputtextalign};` : null}
    ${({ inputfontweight }) => inputfontweight ? `font-weight: ${inputfontweight};` : null}
    
    &:disabled {
        color: ${styledProps('color', 'silverChalice')};
        -webkit-text-fill-color: ${styledProps('color', 'silverChalice')};
        opacity: 1;
    }
    
    &::-ms-clear {
        width : 0;
        height: 0;
    }
`;

const StyledDateInput = styled(DateInput)`
     font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
     font-size: ${({ inputfontsize }) => inputfontsize ? rem(inputfontsize) : '' };
     &:disabled {
        color: ${styledProps('color', 'silverChalice')};
        -webkit-text-fill-color: ${styledProps('color', 'silverChalice')};
        opacity: 1;
    }
    &::-ms-clear {
        width : 0;
        height: 0;
    }
`;

const ClearSearchIcon = styled.div`
     position: absolute;
     bottom: 15px;
     right: 15px;
     width: 15px;
     height: 15px;
     
     &:before,
     &:after {
        width: 100%;
        position: absolute;
        top:0;
        content:"";
        border-bottom: 1px solid ${styledProps('color', 'black')};
        transform: rotate(45deg);
     }
     &:before {
        transform: rotate(-45deg);
     }
`;
const InputWrapper = styled.div`
    position: relative;
    ${({ expand }) => expand ? 'width: 100%;' : ''}
    ${({ halfSize }) => halfSize ? 'width: 50%;' : ''}
    
`;

export class FormInput extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        onChange: PropTypes.func,
        initialValue: PropTypes.any.isRequired,
        onSubmit: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        placeholder: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        caption: PropTypes.string,
        error: PropTypes.object,
        validationMessageOverwrite: PropTypes.object,
        pattern: PropTypes.string,
        loading: PropTypes.bool,
        value: PropTypes.string,
        autoComplete: PropTypes.string,
        labelFont: PropTypes.string,
        labelFontSize: PropTypes.number,
        labelFontSizeNonEmpty: PropTypes.number,
        labelWeight: PropTypes.string,
        labelColor: PropTypes.string,
        inputfont: PropTypes.string,
        inputfontsize: PropTypes.number,
        inputtextalign: PropTypes.string,
        inputfontweight: PropTypes.string,
        borderColor: PropTypes.string,
        noPadding: PropTypes.bool,
        multiLine: PropTypes.bool,
        labelActiveByDefault: PropTypes.bool,
        captionPadding: PropTypes.array,
        disabled: PropTypes.bool,
        clearOption: PropTypes.bool,
        onClear: PropTypes.func,
        maxLength: PropTypes.any,
        renderUnderline: PropTypes.bool,
        underlineWidth: PropTypes.string,
        hideLabelOnFocus: PropTypes.bool,
        setIsMobileKeyboardOpen: PropTypes.func,
        showServerError: PropTypes.bool,
        resetValue: PropTypes.bool,
        expand: PropTypes.bool,
        halfSize: PropTypes.bool,
        inputfontcolor: PropTypes.string,
        multiLineInitialBlockHeight: PropTypes.string,
        displayPassword: PropTypes.bool,
        srOnlyLabel: PropTypes.string,
        ariaLabel: PropTypes.string,
        noOutline: PropTypes.bool,
        alwaysMobile: PropTypes.bool,
        ignoreValidationMessage: PropTypes.bool,
        inputRef: PropTypes.object,
        resetInputOnEmptyValueProp: PropTypes.bool,
        overrideBorderColor: PropTypes.string,
        displayFlexMultiline: PropTypes.bool,
        multiLineLength: PropTypes.number,
        inputPadding: PropTypes.array, // currently only for pseudo text area
        inputFullWidth: PropTypes.bool, // currently only for pseudo text area
        cssPadding: PropTypes.array, // wrapper padding
        errorFont: PropTypes.string,
        useEmptyOption: PropTypes.bool
    };

    static defaultProps = {
        type: 'text',
        onBlur: noop,
        onFocus: noop,
        error: {},
        initialValue: '',
        clearOption: false,
        maxLength: undefined,
        renderUnderline: true,
        setIsMobileKeyboardOpen: noop,

        /**
         * Read https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
         * for possible validity states.
         */
        validationMessageOverwrite: {},
        showServerError: true,
        alwaysMobile: false,
        ignoreValidationMessage: false,
        resetInputOnEmptyValueProp: false
    };

    state = {
        isFocused: false,
        isInvalid: false,
        validity: {},
        validationMessage: '',
        value: this.props.initialValue || this.props.value || ''
    };

    componentDidMount() {
        const { multiLine, value } = this.props;

        if (multiLine && value) {
            this.pseudoTextAreaRef.current.innerHTML = value;
        }
    }

    // handle value prop updated
    componentDidUpdate(prevProps) {
        // Allow resetting the value via props
        if (this.state.value !== '' && this.props.resetValue) {
            this.handleInputReset();
        }

        // If value changed programmatically, focus on input to render labels correctly
        if (prevProps.value !== this.props.value && !this.props.resetInputOnEmptyValueProp) {
            this.setState({
                isFocused: true
            });
        } else if (prevProps.value !== this.props.value && this.props.resetInputOnEmptyValueProp && !this.props.value) {
            this.setState({
                isFocused: false,
                value: ''
            });
        }
    }

    // The resolved input component based on the type prop
    Input = resolveKey(
        {
            tel: StyledPhoneInput,
            password: PasswordInput,
            date: StyledDateInput,
            select: SelectInput,
            threeFieldsDate: ThreeFieldsDate
        },
        this.props.type,
        StyledTextInput
    );

    // Update input validity
    updateValidity = event => {
        const { validity, validationMessage } = event.target;
        const validationMessageOverwrite = resolveBooleanKey(
            this.props.validationMessageOverwrite,
            validity
        );

        this.setState({
            validity,
            validationMessage: validationMessageOverwrite || validationMessage
        });
    };

    // Handle when the user is focusing on the input
    handleFocus = event => {
        // Change the global app scrolling behavior to handle
        // iOS window sizing issues when the keyboard is invoked
        if (isMobile.iOS()) {
            this.props.setIsMobileKeyboardOpen(true);
        }
        this.updateValidity(event);
        this.setState({
            isFocused: true
        });
        this.props.onFocus(event);
    };

    // Handle when the user leaves the input.
    handleBlur = event => {
        if (isMobile.iOS()) {
            this.props.setIsMobileKeyboardOpen(false);
        }
        this.setState({
            isFocused: false,
            isInvalid: !this.props.ignoreValidationMessage && Boolean(this.state.validationMessage)
        });
        this.props.onBlur(event);
    };

    // Handle when the user changes the input value.
    handleChange = (event, isMultiLine) => {
        const { onChange } = this.props;

        this.setState({
            value: isMultiLine ? event.target.innerHTML : event.target.value,
        });

        this.updateValidity(event);
        if (onChange) {
            this.props.onChange(event);
        }
    };

    handleKeyDown(event) {
        const { onSubmit, multiLine, maxLength, multiLineLength } = this.props;
        const { value } = this.state;
        const code = event.keyCode || event.which;

        // handle max length for multi line input
        if (multiLine && maxLength && event.target.innerHTML.length >= maxLength && !ignoredKeyCodes.includes(code)) {
            event.preventDefault();
        }

        // handle max length ignoring html tags for multi line input
        if (multiLine && multiLineLength && !ignoredKeyCodes.includes(code)) {
            const normalizedText = event.target.innerHTML
                .replace(/<div>|&nbsp;/g, ' ')
                .replace(/<\/div>|<br>|<br\/>/g, '')
                .replace(/&amp;/g, '&');

            if (normalizedText.length >= multiLineLength) {
                event.preventDefault();
            }
        }

        if (event.keyCode === 13 && onSubmit) {
            event.preventDefault();
            onSubmit(value);
        }
    }

    handleInputReset = () => {
        const { multiLine, onClear } = this.props;

        if (multiLine) {
            this.pseudoTextAreaRef.current.innerHTML = '';
        }

        this.setState({ value: '' });
        if (onClear) {
            onClear();
        }
    };

    pseudoTextAreaRef = React.createRef();

    render() {
        const { Input } = this;
        const {
            id,
            error,
            type,
            label,
            required,
            caption,
            validationMessageOverwrite,
            loading,
            placeholder,
            initialValue,
            autoComplete,
            labelFont,
            labelFontSize,
            labelFontSizeNonEmpty,
            labelWeight,
            inputfont,
            inputfontsize,
            labelColor,
            inputtextalign,
            borderColor,
            noPadding,
            cssPadding,
            multiLine,
            labelActiveByDefault,
            captionPadding,
            disabled,
            clearOption,
            renderUnderline,
            underlineWidth,
            hideLabelOnFocus,
            setIsMobileKeyboardOpen,
            showServerError,
            inputfontcolor,
            expand,
            halfSize,
            multiLineInitialBlockHeight,
            displayPassword,
            srOnlyLabel,
            ariaLabel,
            alwaysMobile,
            inputRef,
            ignoreValidationMessage,
            resetInputOnEmptyValueProp,
            overrideBorderColor,
            displayFlexMultiline,
            inputPadding,
            inputFullWidth,
            inputfontweight,
            errorFont,
            ...rest
        } = this.props;
        const { isInvalid, isFocused, validationMessage, value } = this.state;
        const errorMessage = (isInvalid && validationMessage) || (showServerError && error[id]);
        const isDisabled = disabled || loading;

        const errorDescribedBy = {};

        if (errorMessage) {
            errorDescribedBy['aria-describedby'] = `${id}_error-message`;
        }

        return (
            <InputWrapper expand={expand} halfSize={halfSize}>
                <InputContainer noPadding={noPadding} cssPadding={cssPadding}>
                    <StyledInputLabel
                        htmlFor={id}
                        labelFont={labelFont ? labelFont : inputfont}
                        labelfontSize={labelFontSize ? labelFontSize : inputfontsize}
                        labelFontSizeNonEmpty={labelFontSizeNonEmpty ? labelFontSizeNonEmpty : labelFontSize ? labelFontSize : inputfontsize}
                        labelWeight={labelWeight}
                        isPlaceholder={(!(isFocused || value) && !value.length && !labelActiveByDefault)}
                        required={required}
                        labelColor={labelColor}
                        hideOnFocus={hideLabelOnFocus && (isFocused || value)}
                        textAlign={inputtextalign}
                    >
                        {!!srOnlyLabel && <span aria-hidden={true}>{placeholder || label}</span>}
                        {!!srOnlyLabel && <span className={'sr-only'}>{srOnlyLabel}</span>}
                        {!srOnlyLabel && (placeholder || label)}
                    </StyledInputLabel>
                    {multiLine ? (<PseudoTextArea
                        {...{ id, required, value, type, autoComplete, ...rest }}
                        onChange={(event) => this.handleChange(event, multiLine)}
                        onSelect={(event) => this.handleChange(event, multiLine)}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        inputfont={inputfont}
                        inputfontsize={inputfontsize}
                        inputtextalign={inputtextalign}
                        inputfontcolor={inputfontcolor}
                        onKeyDown={(event) => this.handleKeyDown(event)}
                        contentEditable={!isDisabled}
                        multiLineInitialBlockHeight={multiLineInitialBlockHeight}
                        ref={this.pseudoTextAreaRef}
                        displayFlexMultiline={displayFlexMultiline}
                        padding={inputPadding}
                        fullWidth={inputFullWidth}
                        dangerouslySetInnerHTML={{ __html: initialValue }}
                    ></PseudoTextArea>) : (<Input
                        {...{ id, required, value, type, autoComplete, ...rest }}
                        {...errorDescribedBy}
                        inputfont={inputfont}
                        inputfontcolor={inputfontcolor}
                        inputfontsize={inputfontsize}
                        inputtextalign={inputtextalign}
                        inputfontweight={inputfontweight}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onSelect={this.handleChange}
                        disabled={isDisabled}
                        displaypassword={displayPassword}
                        ariaLabel={ariaLabel}
                        alwaysMobile={alwaysMobile}
                        inputRef={inputRef}
                    />)}
                    {renderUnderline && type !== 'threeFieldsDate' &&
                    <StyledInputUnderline
                        isActive={isFocused}
                        isLoading={loading}
                        borderColor={borderColor}
                        hasError={Boolean(errorMessage)}
                        underlineWidth={underlineWidth}
                        overrideBorderColor={overrideBorderColor}
                    />}
                    {errorMessage && (
                        <InputError id={`${id}_error-message`} aria-live={'assertive'} role={'alert'} fontFamily={errorFont}>
                            {errorMessage.replace(finalDotRegex, '')}
                        </InputError>
                    )}
                    {caption && (
                        <InputCaption padding={captionPadding} dangerouslySetInnerHTML={{ __html: caption }} />
                    )}
                </InputContainer>
                {clearOption && value.length > 0 && <ClearSearchIcon
                    className="closeIcon"
                    onClick={this.handleInputReset}
                />}
            </InputWrapper>
        );
    }
}

export default withMobileKeyboard(FormInput);

